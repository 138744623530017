/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../layout/layout'
import { Hero, Section, ServicePreview } from '../components'
class ServicesIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const service = get(this.props, 'data.allContentfulService.edges')

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={`Services | ${siteTitle}`} />
          <Hero title='Services' size={[4, 6]} minHeight={[0, '40vh']} />
          <Section variant='transparent'>
            <div
              sx={{
                display: 'grid',
                gridTemplateColumns: [
                  'repeat(auto-fill, minmax(250px, 1fr))',
                  'repeat(auto-fill, minmax(400px, 1fr))',
                ],
                gridAutoRows: '1fr',
                gridGap: 4,
                alignItems: 'stretch',
              }}
            >
              {service.map(({ node }) => {
                return <ServicePreview service={node} key={node.slug} />
              })}
            </div>
          </Section>
        </div>
      </Layout>
    )
  }
}

export default ServicesIndex

export const pageQuery = graphql`
  query ServicesIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulService {
      edges {
        node {
          title
          subtitle
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid {
              base64
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  }
`
